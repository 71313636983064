<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>Add Stock</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                  v-model="newDevice.serial"
                  label="Device Serial No"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                  v-model="newDevice.location"
                  label="Location"
              />
            </v-col>
            <v-col cols="12">
              <v-combobox
                  v-model="newDevice.device_type"
                  :items="deviceType"
                  label="Device Type"
              />
            </v-col>
            <v-col cols="12">
              <v-combobox
                  v-model="newDevice.enclosure_type"
                  :items="enclosureType"
                  label="Enclosure Type"
              />
            </v-col>
            <v-col cols="12">
              <v-btn
                  v-if="!getCode"
                  color="blue"
                  dark
                  outlined
                  @click="getCode = true">
                Scan Code
              </v-btn>
              <qrcode-stream v-if="getCode" @decode="onDecode" @init="onInit"></qrcode-stream>
              <div v-if="newDevice.qrid.length > 0">QR ID: {{newDevice.qrid}}</div>
            </v-col>
            <v-col cols="12">
              <v-btn
                  v-if="deviceValid()"
                  color="green"
                  dark
                  outlined
                  @click="createDevice()">
                Add Device
              </v-btn>
              <span v-if="!deviceValid()">Form Incomplete</span>
            </v-col>
          </v-row>
        </v-card-text>
      </base-card>
    </v-col>

  </v-row>
</template>

<script>

import { QrcodeStream } from 'vue-qrcode-reader'
import toastr from "toastr";


export default {
  name: "AddDevice",
  components: {
    QrcodeStream,
  },
  data() {
    return {
      getCode: false,
      newDevice: {
        serial: '',
        location: 'Stokesley',
        qrid: '',
        enclosure_type: 'none',
        device_type: '',
        device_status: 'stock'
      },
      enclosureType: ['none', 'In Enclosure'],
      deviceType: ['AR-01', 'AC-01','other'],
    }
  },
  computed: {

  },
  methods: {
    deviceValid(){
      if(this.newDevice.qrid !== '' && this.newDevice.location !== '' && this.newDevice.enclosure_type !== '' &&this.newDevice.device_type !== '') {
        return true
      }
      return false;
    },
    async onInit (promise) {
      // show loading indicator

      try {
        const { capabilities } = await promise

        // successfully initialized
      } catch (error) {
        alert(error.name);
        if (error.name === 'NotAllowedError') {
          // user denied camera access permisson
        } else if (error.name === 'NotFoundError') {
          // no suitable camera device installed
        } else if (error.name === 'NotSupportedError') {
          // page is not served over HTTPS (or localhost)
        } else if (error.name === 'NotReadableError') {
          // maybe camera is already in use
        } else if (error.name === 'OverconstrainedError') {
          // did you requested the front camera although there is none?
        } else if (error.name === 'StreamApiNotSupportedError') {
          // browser seems to be lacking features
        }
      } finally {
        // hide loading indicator
      }
    },
    onDecode (result) {
      if(result.startsWith('https://cnmx.io/d/')) {
        this.newDevice.qrid = result.substring(18);
        this.getCode = false;
      } else {
        toastr.error('Not an Emissis Device!');
        this.getCode = false;
      }

    },
    createDevice() {
      this.$store.dispatch('devices/create', this.newDevice).then((result) => {
        toastr.success('Successfully added a device');
        this.newDevice = {
          serial: '',
          location: 'Stokesley',
          qrid: '',
          enclosure_type: '',
          meter_type: '',
          device_type: '',
          device_status: 'stock'
        };
      }).catch((error) => {
        toastr.error(error);
      })
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
